var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-5",attrs:{"elevation":"0"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rows,"loading":_vm.isLoading,"search":_vm.search,"sort-by":"title"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Search")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search row","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v("New Project")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Title","outlined":"","hide-details":""},model:{value:(_vm.editedItem.title),callback:function ($$v) {_vm.$set(_vm.editedItem, "title", $$v)},expression:"editedItem.title"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Project key","disabled":_vm.editedIndex > -1,"outlined":"","hide-details":""},model:{value:(_vm.editedItem.code),callback:function ($$v) {_vm.$set(_vm.editedItem, "code", $$v)},expression:"editedItem.code"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Description","outlined":"","hide-details":""},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"Have export action","items":[
                        { title: 'True', value: true },
                        { title: 'False', value: false } ],"item-text":"title","item-value":"value","outlined":"","hide-details":""},model:{value:(_vm.editedItem.haveExport),callback:function ($$v) {_vm.$set(_vm.editedItem, "haveExport", $$v)},expression:"editedItem.haveExport"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Columns","disabled":"","outlined":"","hide-details":""},model:{value:(_vm.editedItem.columns),callback:function ($$v) {_vm.$set(_vm.editedItem, "columns", $$v)},expression:"editedItem.columns"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":"","large":""},on:{"click":_vm.save}},[_vm._v("Save")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","large":""},on:{"click":_vm.close}},[_vm._v("Cancel")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.title",fn:function(ref){
                      var item = ref.item;
return [_c('a',{staticClass:"font-weight-bold text-decoration-none primary-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.columns",fn:function(ref){
                      var item = ref.item;
return _vm._l((item.columns),function(column){return _c('v-chip',{key:column.value,staticClass:"ma-1",attrs:{"color":column.visibleInExport ? 'success' : 'error',"outlined":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-server-plus ")]),_vm._v(" "+_vm._s(column.text)+" ")],1)})}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"color":"primary","disabled":item.role === 'admin',"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }