<template>
  <v-card class="pa-5" elevation="0">
    <v-data-table :headers="headers" :items="rows" :loading="isLoading" :search="search" sort-by="title">
      <template v-slot:top>
        <v-toolbar flat dense>
          <v-toolbar-title>Search</v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search row"
            single-line
            hide-details
          ></v-text-field>

          <v-divider class="mx-4" inset vertical></v-divider>

          <v-dialog v-model="dialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" class="mb-2" dark v-bind="attrs" v-on="on">New Project</v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field v-model="editedItem.title" label="Title" outlined hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="editedItem.code"
                        label="Project key"
                        :disabled="editedIndex > -1"
                        outlined
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="editedItem.description"
                        label="Description"
                        outlined
                        hide-details
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="editedItem.haveExport"
                        label="Have export action"
                        :items="[
                          { title: 'True', value: true },
                          { title: 'False', value: false },
                        ]"
                        item-text="title"
                        item-value="value"
                        outlined
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="editedItem.columns"
                        label="Columns"
                        disabled
                        outlined
                        hide-details
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-btn color="primary" text large @click="save">Save</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" text large @click="close">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"> Are you sure you want to delete this item? </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.title="{ item }">
        <a href="#" class="font-weight-bold text-decoration-none primary-item" @click.prevent="editItem(item)">
          {{ item.title }}
        </a>
      </template>

      <template v-slot:item.columns="{ item }">
        <v-chip
          v-for="column in item.columns"
          :key="column.value"
          class="ma-1"
          :color="column.visibleInExport ? 'success' : 'error'"
          outlined
          small
        >
          <v-icon left> mdi-server-plus </v-icon>
          {{ column.text }}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" color="primary" small @click="editItem(item)">mdi-pencil</v-icon>

        <v-icon color="primary" :disabled="item.role === 'admin'" small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { latinMap } from '../config/variables';

export default {
  name: 'ProjectsSettings',
  data() {
    return {
      search: '',
      headers: [
        { text: 'Title', align: 'start', filterable: true, value: 'title', width: '10%' },
        { text: 'Project key', align: 'start', filterable: true, value: 'code', width: '10%' },
        {
          text: 'Description',
          align: 'start',
          filterable: false,
          value: 'description',
          width: '20%',
        },
        { text: 'Columns', align: 'start', filterable: true, value: 'columns', width: '50%' },
        { text: 'Export', align: 'start', filterable: true, value: 'haveExport', width: '5%' },
        { text: 'Actions', value: 'actions', filterable: false, sortable: false, width: '5%' },
      ],
      loaded: false,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        title: '',
        code: '',
        description: '',
        haveExport: true,
        columns: [],
      },
      defaultItem: {
        title: '',
        code: '',
        description: '',
        haveExport: true,
        columns: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      isManager: 'user/isManager',
      projects: 'projects/list',
    }),
    isLoading() {
      return !this.loaded;
    },
    formTitle() {
      return `${-1 === this.editedIndex ? 'New' : 'Edit'} Project`;
    },
    rows() {
      return this.loaded ? this.projects : [];
    },
    normalizedCode() {
      return this.editedItem.code
        .replace(/[^A-Za-z0-9[\] ]/g, function (a) {
          return latinMap[a] || a;
        })
        .replaceAll(' ', '-')
        .toLowerCase();
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    'editedItem.code': {
      handler: function () {
        this.editedItem.code = this.normalizedCode;
      },
    },
  },
  created() {
    this.loaded = false;

    const fetchProjects = this.isManager ? this.fetchProjects() : Promise.resolve();

    Promise.all([fetchProjects])
      .then(() => {
        setTimeout(() => {
          this.loaded = true;
        }, 800);
      })
      .catch(() => {
        this.showAlert({
          message: 'Error occurred while fetching projects',
        });
      });
  },
  methods: {
    ...mapActions({
      showAlert: 'alert/show',
      fetchProjects: 'projects/fetchProjects',
      addProject: 'projects/addProject',
      updateProject: 'projects/updateProject',
      deleteProject: 'projects/deleteProject',
    }),
    editItem(item) {
      this.editedIndex = this.projects.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.projects.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      try {
        await this.deleteProject(this.editedItem);
        this.closeDelete();
      } catch (e) {
        this.showAlert({
          message: e.message,
        });
        this.closeDelete();
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      try {
        if (-1 < this.editedIndex) {
          this.updateProject(this.editedItem);
        } else {
          this.addProject(this.editedItem);
        }
      } catch (e) {
        this.showAlert({
          message: e.message,
        });
      }

      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.primary-item {
  color: var(--v-primary-base) !important;
}
.error-item {
  color: var(--v-secondary-base) !important;
}
</style>
